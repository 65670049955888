import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';
import Avaliacao from 'pages/avaliacao/pre-avaliacao';
import Login from 'pages/login';
import Home from 'pages/home';
import AgendamentoPage from 'pages/avaliacao/agendamento';
import EntradaProdutos from 'pages/avaliacao/entrada-produtos';
import AvaliacaoFisicaProduto from 'pages/avaliacao/avaliacao-fisica-produto';
import AvaliacaoFisicaBatch from 'pages/avaliacao/avaliacao-fisica-batch';
import AvaliacaoFisicaNegociacao from 'pages/avaliacao/avaliacao-fisica-negociacao';
import AvaliacaoDetailsPage from 'pages/avaliacao/pre-avaliacao/details';
import AvaliacaoFisicaProdutoDetailsPage from 'pages/avaliacao/avaliacao-fisica-produto/details';
import AvaliacaoFisicaBatchDetailsPage from 'pages/avaliacao/avaliacao-fisica-batch/details';
import EntradaManual from 'pages/avaliacao/entrada-manual';
import ForcarCaptura from 'pages/ferramentas/forcar-captura';
import CancelarPedido from 'pages/ferramentas/cancelar-pedido';
import FreteGratis from 'pages/ferramentas/frete-gratis';
import VendasVendedora from 'pages/relatorios/vendas-vendedora';
import LifecycleVoucher from 'pages/lifecycle/voucher';
import MarcarProdutosLocalizados from 'pages/localizar-produtos/marcar-localizado';
import UploadImagens from 'pages/upload-imagens';
import PickingFotos from 'pages/avaliacao/avaliacao-fisica-produto/picking-fotos';
import AvaliacaoNegociacao from 'pages/avaliacao/pre-avaliacao-negociacao';
import AvaliacaoNegociacaoDetailsPage from 'pages/avaliacao/pre-avaliacao-negociacao/details';
import AguardandoEnvio from 'pages/avaliacao/aguardando-envio';
import EntradaAcervo from 'pages/entrada-acervo';
import ComissionamentoLista from 'pages/comissionamento';
import ComissionamentoDetailsPage from 'pages/comissionamento/details';
import ComissionadasLista from 'pages/comissionadas';
import ComissionadasDetailsPage from 'pages/comissionadas/details';
import LocalizarProduto from 'pages/localizar-produtos/localizar';
import Reetiquetagem from 'pages/reetiquetagem';
import Picking from 'pages/expedicao/picking';
import Checking from 'pages/expedicao/checking';
import CheckingDetails from 'pages/expedicao/checking/details';
import Packing from 'pages/expedicao/packing';
import ListaPortador from 'pages/expedicao/portador';
import EtiquetasDevolucao from 'pages/etiquetas-devolucao';
import CriarAgendarTranferencias from 'pages/ferramentas/transferencias/criar-agendar';
import ListarTranferencia from 'pages/ferramentas/transferencias/listar';
import SeparacaoProdutosPorPreco from 'pages/separacao-produtos-por-preco';
import ProdutosNaoEncontrados from 'pages/expedicao/nao-encontrados';
import AvaliacaoFisicaNegociacaoDetailsPage from 'pages/avaliacao/avaliacao-fisica-negociacao/details';
import Devolucao from 'pages/devolucao';
import IncluirDevolucao from 'pages/devolucao/incluir-devolucao';
import AssociarQuiosque from 'pages/associar-quiosque';
import RedefinirSenha from 'pages/ferramentas/redefinir-senha';
import PagamentosVendedoras from 'pages/ferramentas/pagamentos-vendedoras';
import PickingDoacao from 'pages/expedicao/picking-doacao';
import PickingDevolucao from 'pages/expedicao/picking-devolucao';
import CheckingDevolucao from 'pages/expedicao/checking-devolucao';
import CheckingDevolucaoDetails from 'pages/expedicao/checking-devolucao/details';
import PackingDevolucao from 'pages/expedicao/packing-devolucao';
import CadastrarModelo from 'pages/modelos/cadastrar';
import ListarModelos from 'pages/modelos/listar';
import GerarCarrinho from 'pages/ferramentas/gerar-carrinho';
import ExibirCarrinho from 'pages/ferramentas/exibir-carrinho';
import ModeracaoProduto from 'pages/parceiros/moderacao-produtos/produto';
import ModeracaoListagem from 'pages/parceiros/moderacao-produtos';
import AssociaVendedorInternoCanal from 'pages/ferramentas/associar-vendedor-interno-canal';
import Campanhas from 'pages/painel-marketing/gerenciar-campanhas';
import EditarCampanhas from 'pages/painel-marketing/gerenciar-campanhas/editar';
import CriarCampanhas from 'pages/painel-marketing/gerenciar-campanhas/criar';
import PainelMarketingGerenciarTags from 'pages/painel-marketing/gerenciar-tags';
import GerarAcessoTemporario from 'pages/gerar-acesso-temporario';
import PainelMarketingFaixas from 'pages/painel-marketing/gerenciar-faixas';
import CadastrarFaixa from 'pages/painel-marketing/gerenciar-faixas/cadastrar-faixa';
import DetalhesFaixa from 'pages/painel-marketing/gerenciar-faixas/detalhes-faixa';
import AdicionarFotosParceiro from 'pages/adicionar-fotos-parceiro';
import ExcluirPeca from 'pages/ferramentas/excluir-peca';
import UnificarContaCliente from '../pages/ferramentas/unificar-conta-cliente';
import { withRequireAuth } from './withRequireAuth';

const rqa = withRequireAuth;

function Routes() {
  return (
    <Switch>
      <Route path="/" Component={rqa(Login)} />
      <Route path="/login" Component={rqa(Login)} />
      <Route path="/home" Component={rqa(Home, { isPrivate: true })} />

      <Route path="/parceiros/moderacao-produtos/:codproduto" Component={rqa(ModeracaoProduto, { isPrivate: true })} />
      <Route path="/parceiros/moderacao-produtos" Component={rqa(ModeracaoListagem, { isPrivate: true })} />
      {/* Marketing */}
      <Route path="/painel-marketing/campanhas" Component={rqa(Campanhas, { isPrivate: true })} />
      <Route
        path="/painel-marketing/campanhas/editar/:id/:tabSelected?"
        Component={rqa(EditarCampanhas, { isPrivate: true })}
      />
      <Route path="/painel-marketing/campanhas/criar" Component={rqa(CriarCampanhas, { isPrivate: true })} />

      {/* Pré-Avaliação */}
      <Route path="/pre-avaliacao" Component={rqa(Avaliacao, { isPrivate: true })} />
      <Route path="/pre-avaliacao/batch/:id" Component={rqa(AvaliacaoDetailsPage, { isPrivate: true })} />
      <Route
        path="/pre-avaliacao/pre-avaliacao-negociacao/:id"
        Component={rqa(AvaliacaoNegociacaoDetailsPage, { isPrivate: true })}
      />
      <Route path="/pre-avaliacao/pre-avaliacao-negociacao" Component={rqa(AvaliacaoNegociacao, { isPrivate: true })} />
      <Route path="/pre-avaliacao/aguardando-envio" Component={rqa(AguardandoEnvio, { isPrivate: true })} />
      <Route path="/pre-avaliacao/agendamento" Component={rqa(AgendamentoPage, { isPrivate: true })} />
      <Route path="/pre-avaliacao/entrada" Component={rqa(EntradaProdutos, { isPrivate: true })} />
      {/* Avaliação */}
      <Route
        path="/avaliacao/avaliacao-fisica-batch/:codquerovenderitem"
        Component={rqa(AvaliacaoFisicaBatchDetailsPage, { isPrivate: true })}
      />
      <Route
        path="/avaliacao/avaliacao-fisica-produto/:codquerovenderitem"
        Component={rqa(AvaliacaoFisicaProdutoDetailsPage, { isPrivate: true })}
      />
      <Route path="/avaliacao/avaliacao-fisica-batch" Component={rqa(AvaliacaoFisicaBatch, { isPrivate: true })} />
      <Route path="/avaliacao/avaliacao-fisica-produto" Component={rqa(AvaliacaoFisicaProduto, { isPrivate: true })} />
      <Route
        path="/avaliacao/avaliacao-fisica-negociacao/:codquerovender"
        Component={rqa(AvaliacaoFisicaNegociacaoDetailsPage, { isPrivate: true })}
      />
      <Route
        path="/avaliacao/avaliacao-fisica-negociacao"
        Component={rqa(AvaliacaoFisicaNegociacao, { isPrivate: true })}
      />
      {/* Entrada Manual */}
      <Route path="/entrada-manual/:codquerovender" Component={rqa(EntradaManual, { isPrivate: true })} />
      <Route path="/entrada-manual/" Component={rqa(EntradaManual, { isPrivate: true })} />
      {/* Fotografia */}
      <Route path="/fotografia/picking-fotos" Component={rqa(PickingFotos, { isPrivate: true })} />
      <Route path="/fotografia/upload-imagens" Component={rqa(UploadImagens, { isPrivate: true })} />
      <Route path="/fotografia/adicionar-fotos-parceiro" Component={rqa(AdicionarFotosParceiro, { isPrivate: true })} />

      {/* Devolução */}
      <Route path="/devolucao" Component={rqa(Devolucao, { isPrivate: true })} />
      <Route path="/avaliacao/incluir-devolucao/:codigo" Component={rqa(IncluirDevolucao, { isPrivate: true })} />
      {/* Acervo */}
      <Route path="/acervo/entrada-acervo" Component={rqa(EntradaAcervo, { isPrivate: true })} />
      <Route path="/acervo/localizar" Component={rqa(LocalizarProduto, { isPrivate: true })} />
      <Route
        path="/acervo/separacao-produtos-por-preco"
        Component={rqa(SeparacaoProdutosPorPreco, { isPrivate: true })}
      />
      <Route path="/acervo/reetiquetagem" Component={rqa(Reetiquetagem, { isPrivate: true })} />
      {/* Ferramentas Administrativas */}
      <Route path="/administrativo/forcar-captura" Component={rqa(ForcarCaptura, { isPrivate: true })} />
      <Route path="/administrativo/cancelar-pedido" Component={rqa(CancelarPedido, { isPrivate: true })} />
      <Route
        path="/administrativo/transferencias/criar-agendar"
        Component={rqa(CriarAgendarTranferencias, { isPrivate: true })}
      />
      <Route path="/administrativo/transferencias/listar" Component={rqa(ListarTranferencia, { isPrivate: true })} />
      <Route path="/administrativo/pagamentos-vendedoras" Component={rqa(PagamentosVendedoras, { isPrivate: true })} />
      <Route path="/administrativo/lifecycle-voucher" Component={rqa(LifecycleVoucher, { isPrivate: true })} />
      <Route path="/administrativo/unificar-conta-cliente" Component={rqa(UnificarContaCliente, { isPrivate: true })} />
      {/* Relatórios */}
      <Route path="/relatorios/vendas-vendedora" Component={rqa(VendasVendedora, { isPrivate: true })} />
      {/* Expedição de pedidos */}
      <Route path="/expedicao/picking" Component={rqa(Picking, { isPrivate: true })} />
      <Route path="/expedicao/checking/:codigo_pedido" Component={rqa(CheckingDetails, { isPrivate: true })} />
      <Route path="/expedicao/checking" Component={rqa(Checking, { isPrivate: true })} />
      <Route path="/expedicao/packing" Component={rqa(Packing, { isPrivate: true })} />
      <Route path="/expedicao/portador" Component={rqa(ListaPortador, { isPrivate: true })} />
      <Route path="/expedicao/nao-encontrados" Component={rqa(ProdutosNaoEncontrados, { isPrivate: true })} />
      <Route path="/expedicao/marcar-localizado" Component={rqa(MarcarProdutosLocalizados, { isPrivate: true })} />
      {/* Devolução de vendedor */}
      <Route path="/devolucao/picking-devolucao" Component={rqa(PickingDevolucao, { isPrivate: true })} />
      <Route
        path="/devolucao/checking-devolucao/:codigo_pedido"
        Component={rqa(CheckingDevolucaoDetails, { isPrivate: true })}
      />
      <Route path="/devolucao/checking-devolucao" Component={rqa(CheckingDevolucao, { isPrivate: true })} />
      <Route path="/devolucao/packing-devolucao" Component={rqa(PackingDevolucao, { isPrivate: true })} />
      <Route path="/devolucao/picking-doacao" Component={rqa(PickingDoacao, { isPrivate: true })} />
      <Route path="/devolucao/etiquetas-devolucao" Component={rqa(EtiquetasDevolucao, { isPrivate: true })} />
      {/* Ferramentas */}
      <Route path="/ferramentas/comissionamento/:id" Component={rqa(ComissionamentoDetailsPage, { isPrivate: true })} />
      <Route path="/ferramentas/comissionamento" Component={rqa(ComissionamentoLista, { isPrivate: true })} />
      <Route path="/ferramentas/comissionadas/:id" Component={rqa(ComissionadasDetailsPage, { isPrivate: true })} />
      <Route path="/ferramentas/comissionadas" Component={rqa(ComissionadasLista, { isPrivate: true })} />
      <Route path="/ferramentas/associar-quiosque" Component={rqa(AssociarQuiosque, { isPrivate: true })} />
      <Route path="/ferramentas/frete-gratis" Component={rqa(FreteGratis, { isPrivate: true })} />
      <Route
        path="/ferramentas/associar-vendedor-interno-canal"
        Component={rqa(AssociaVendedorInternoCanal, { isPrivate: true })}
      />
      <Route path="/ferramentas/gerar-acesso-temporario" Component={rqa(GerarAcessoTemporario, { isPrivate: true })} />

      {/* <Route path="/ferramentas/carrinho" Component={rqa(Carrinho, { isPrivate: true})}  /> */}
      <Route path="/ferramentas/gerar-carrinho" Component={rqa(GerarCarrinho, { isPrivate: true })} />
      <Route path="/ferramentas/carrinho/:codcarrinho" Component={rqa(ExibirCarrinho, { isPrivate: true })} />
      <Route path="/ferramentas/excluir-peca" Component={rqa(ExcluirPeca, { isPrivate: true })} />
      <Route path="/ferramentas/modelos/cadastrar" Component={rqa(CadastrarModelo, { isPrivate: true })} />
      <Route path="/ferramentas/modelos/listar" Component={rqa(ListarModelos, { isPrivate: true })} />
      <Route path="/ferramentas/url-redefinir-senha" Component={rqa(RedefinirSenha, { isPrivate: true })} />

      {/* Painel de marketing */}

      <Route path="/painel-marketing/gerenciar-faixas" Component={rqa(PainelMarketingFaixas, { isPrivate: true })} />
      <Route
        path="/painel-marketing/gerenciar-faixas/:id/adicionar"
        Component={rqa(CadastrarFaixa, { isPrivate: true })}
      />
      <Route path="/painel-marketing/gerenciar-faixas/editar/:id" Component={rqa(DetalhesFaixa, { isPrivate: true })} />
      <Route
        path="/painel-marketing/gerenciar-tags"
        Component={rqa(PainelMarketingGerenciarTags, { isPrivate: true })}
      />

      {/* Uplaod Imagens */}
      {/* <Route path="/upload-imagens" Component={UploadImagens}  /> */}
    </Switch>
  );
}

export default Routes;
