import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { debounce } from 'components/utils/debounce';
import VendedoraService from 'services/vendedora/VendedoraService';
import { TextFieldDefault, TextFieldCep } from 'components/form-fields-grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

export type FormEnderecoType = {
  cep: string;
  endereco: string;
  numero: string;
  complemento?: string;
  bairro: string;
  cidade: string;
  uf: string;
};

export function FormEndereco() {
  const { register, setValue } = useFormContext();

  const debounceCep = useMemo(
    () =>
      debounce((value: string) => {
        VendedoraService.get_address(value).then(({ data }) => {
          setValue('endereco', data.logradouro);
          setValue('bairro', data.bairro);
          setValue('cidade', data.localidade);
          setValue('uf', data.uf);
        });
      }, 500),
    [setValue]
  );

  const handleCep = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 8) {
      debounceCep(event.target.value);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="overline" color="textSecondary">
          Endereço
        </Typography>
      </Grid>
      <TextFieldCep label="CEP" onChange={handleCep} placeholder="XXXXX-XXX" name="cep" />
      <Grid item sm={4} xs={12} />
      <Grid item sm={4} xs={12} />
      <TextFieldDefault label="Rua" placeholder="Rua" name="endereco" />
      <TextFieldDefault label="Número" placeholder="Número" name="numero" />
      <TextFieldDefault label="Complemento" placeholder="Complemento" name="complemento" />
      <TextFieldDefault label="Bairro" placeholder="Bairro" name="bairro" />
      <TextFieldDefault label="Cidade" placeholder="Cidade" name="cidade" />
      <Grid item sm={4} xs={12}>
        <FormControl fullWidth>
          <InputLabel>Estado</InputLabel>
          <Select margin="dense" native label="Estado" {...register('uf')} fullWidth size="small" variant="outlined">
            <option value="AC">Acre</option>
            <option value="AL">Alagoas</option>
            <option value="AP">Amapá</option>
            <option value="AM">Amazonas</option>
            <option value="BA">Bahia</option>
            <option value="CE">Ceará</option>
            <option value="DF">Distrito Federal</option>
            <option value="ES">Espírito Santo</option>
            <option value="GO">Goiás</option>
            <option value="MA">Maranhão</option>
            <option value="MT">Mato Grosso</option>
            <option value="MS">Mato Grosso do Sul</option>
            <option value="MG">Minas Gerais</option>
            <option value="PA">Pará</option>
            <option value="PB">Paraíba</option>
            <option value="PR">Paraná</option>
            <option value="PE">Pernambuco</option>
            <option value="PI">Piauí</option>
            <option value="RJ">Rio de Janeiro</option>
            <option value="RN">Rio Grande do Norte</option>
            <option value="RS">Rio Grande do Sul</option>
            <option value="RO">Rondônia</option>
            <option value="RR">Roraima</option>
            <option value="SC">Santa Catarina</option>
            <option value="SP">São Paulo</option>
            <option value="SE">Sergipe</option>
            <option value="TO">Tocantins</option>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}
