import api from 'services/api';

const listar_tags = () => {
  return api.get('tag/listar/manual');
};

const listar_produtos = (codtag: number) => {
  return api.get(`tag/${codtag}/produtos`);
};

const associar_produtos = (codtag: number, params: { produtos: { codproduto: number }[] }) => {
  return api.post(`tag/${codtag}/add/produtos`, { ...params });
};

export default { listar_tags, listar_produtos, associar_produtos };
