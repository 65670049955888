import React, { useCallback, useEffect, useState } from 'react';
import TemplateBase from 'components/template-base/template';
import GerenciarTagsService from 'services/painel-marketing/GerenciarTagsService';
import { IProduct } from 'services/painel-marketing/types';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import { Cancel } from '@mui/icons-material';
// import Pagination from '@mui/material/Pagination';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import { ApiListMeta } from 'interfaces';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { CampanhasService } from 'services/painel-marketing/CampanhasService';
import useDebounce from 'hooks/debounce';
import TextField from '@mui/material/TextField';
import red from '@mui/material/colors/red';
import Swal from 'sweetalert2';
import { useLoading } from 'context/loading';
import { Tag } from './types';

function PainelMarketingGerenciarTags() {
  document.title = 'Painel Marketing | Gerenciar Tags';
  const [tags, setTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [searchedProducts, setSearchedProducts] = useState<IProduct[]>([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [query, setQuery] = useState('');
  const [loadingProdutos, setLoadingProdutos] = useState(false);
  // const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const { setLoading } = useLoading();
  // const [page, setPage] = useState(1);

  const queryDebounce = useDebounce(query, 500);

  useEffect(() => {
    setLoadingSearch(true);

    if (queryDebounce === '') return;

    CampanhasService.buscarProdutos(queryDebounce).then(response => {
      setSearchedProducts(response.produtos);
      setLoadingSearch(false);
    });
  }, [queryDebounce]);

  /**
   * Controla paginação
   */
  // const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  // };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleRemoveProduct = (codproduto: number) => {
    setProducts(products.filter(product => product.codproduto !== codproduto));
  };

  const handleAddProduct = (value: IProduct) => {
    setProducts([
      {
        codproduto: value.codproduto,
        nome: value.nome,
        imagens_vitrine: value.imagens_vitrine,
        codigo: value.codigo,
        estoque: value.estoque,
        status: 'A'
      },
      ...products
    ]);

    setTimeout(() => {
      setQuery('');
    }, 0);
  };

  const handleSalvarTag = () => {
    const produtosListagem = products.map(product => ({ codproduto: product.codproduto }));

    setLoading(true);

    GerenciarTagsService.associar_produtos(tags[tabValue].codtag, {
      produtos: produtosListagem
    })
      .then(() => {
        Swal.fire(`Produtos adicionados à ${tags[tabValue].nome}`, '', 'success').then(({ isConfirmed }) => {
          if (isConfirmed) {
            fetchProdutos();
            setQuery('');
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchProdutos = useCallback(() => {
    setLoadingProdutos(true);
    GerenciarTagsService.listar_produtos(tags[tabValue].codtag)
      .then(response => {
        setProducts(response.data.data);
        // setMeta(response.data.meta);
      })
      .finally(() => {
        setLoadingProdutos(false);
      });
  }, [tags, tabValue]);

  const fetchTags = async () => {
    setIsLoading(true);
    GerenciarTagsService.listar_tags()
      .then(response => {
        setTags(response.data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (tags.length === 0) return;

    fetchProdutos();
  }, [tags, tabValue, fetchProdutos]);

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <TemplateBase>
      {isLoading ? (
        <div>Carregando...</div>
      ) : (
        <>
          <h1>Gerenciar Tags</h1>

          <Tabs value={tabValue} onChange={handleChange}>
            {tags.map(tag => (
              <Tab key={tag.codtag} label={tag.nome} />
            ))}
          </Tabs>

          <Box mt={2}>
            {tags.map((tag, index) => {
              return (
                tabValue === index && (
                  <Paper elevation={1} key={tag.codtag}>
                    <Box p={2}>
                      <Typography sx={{ fontSize: 24, fontWeight: 700 }}>{tag.nome}</Typography>

                      <Autocomplete
                        sx={{ marginTop: 2 }}
                        freeSolo
                        inputValue={query}
                        getOptionLabel={option => (option as IProduct).nome}
                        loading={loadingSearch}
                        clearOnEscape
                        filterOptions={option => option}
                        options={searchedProducts}
                        renderInput={params => (
                          <TextField {...params} label="Pesquise um produto para adicionar a coleção" />
                        )}
                        renderOption={(props, option, _, ownerState) => {
                          const { key, ...optionProps } = props;
                          return (
                            <Box
                              key={key}
                              display="flex"
                              alignItems="center"
                              gap={2}
                              sx={{
                                borderRadius: '8px',
                                margin: '5px',
                                [`&.${autocompleteClasses.option}`]: {
                                  padding: '8px'
                                }
                              }}
                              component="li"
                              {...optionProps}
                            >
                              <img
                                width={36}
                                height={36}
                                src={option?.imagens_vitrine?.[0]?.small}
                                style={{ borderRadius: 8 }}
                                alt="imagem produto"
                              />
                              {ownerState.getOptionLabel(option)}
                              {` - ${option?.codigo}`}
                            </Box>
                          );
                        }}
                        onInputChange={(_, value, reason) => {
                          if (reason === 'clear') {
                            setSearchedProducts([]);
                          }
                          setQuery(value);
                        }}
                        onChange={(_, value) => {
                          if (value) {
                            handleAddProduct(value as IProduct);
                          }
                        }}
                      />
                    </Box>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Produto</TableCell>
                          <TableCell align="right" />
                        </TableRow>
                      </TableHead>
                      {loadingProdutos && <Box p={2}>Carregando...</Box>}
                      {products?.length > 0 && !loadingProdutos && (
                        <TableBody>
                          {products.map(product => (
                            <TableRow key={product.nome}>
                              <TableCell align="left">
                                <Box display="flex" alignItems="center" gap={1}>
                                  <img
                                    width={36}
                                    height={36}
                                    src={product?.imagens_vitrine?.[0]?.small}
                                    style={{ borderRadius: 8 }}
                                    alt="imagem produto"
                                  />
                                  <p>
                                    {product.nome}{' '}
                                    {product.estoque ? (
                                      ''
                                    ) : (
                                      <span style={{ color: red[500] }}>(Produto sem estoque)&nbsp;</span>
                                    )}
                                    {product.status !== 'A' && (
                                      <span style={{ color: red[500] }}>(Produto inativo)</span>
                                    )}
                                  </p>
                                </Box>
                              </TableCell>

                              <TableCell align="right">
                                <Button variant="text" onClick={() => handleRemoveProduct(product.codproduto)}>
                                  <Cancel color="action" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                      {products?.length === 0 && !loadingProdutos && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={2}>
                              Nenhum produto cadastrado
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                    {/* <Box display="flex" justifyContent="center" p={3}>
                      <Pagination count={meta?.last_page} page={page} onChange={handlePagination} color="primary" />
                    </Box> */}
                  </Paper>
                )
              );
            })}
          </Box>
          {products?.length > 0 && !loadingProdutos && (
            <Box mt={2}>
              <Button color="primary" variant="contained" onClick={handleSalvarTag}>
                Salvar {tags[tabValue].nome}
              </Button>
            </Box>
          )}
        </>
      )}
    </TemplateBase>
  );
}

export default PainelMarketingGerenciarTags;
