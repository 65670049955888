import React, { useState } from 'react';
import TemplateBase from 'components/template-base/template';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { BadgeClusters } from 'components/custom-badge/badge-clusters';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Barcode } from 'components/icons';
import ItensEmDevolucaoService from 'services/devolucao/ItensEmDevolucaoService';
import { QueroVenderBatch, QueroVenderItemVendedora, Pedido } from 'interfaces';
import { grey, orange, red, blue, green } from '@mui/material/colors';
import { withClusters, WithClustersProps } from 'components/with-clusters';
import Swal from 'sweetalert2';
import { formatTextCpf } from 'helpers/format-text-cpf';
import { formatTextCep } from 'helpers/format-text-cep';
import { formatTextPhone } from 'helpers/format-text-phone';
import Pagination from '@mui/material/Pagination';
import ModalEditarEndereco from './modal-editar-endereco';
import { CardDevolucao } from './card-devolucao';
import { CardDevolucaoItem } from './card-devolucao-item';
import { useFetchPedidoStatus } from './useFetchPedidoStatus';
import CardSkeleton from './card-skeleton';

type ListaDevolucoes = {
  vendedora: QueroVenderItemVendedora;
};

const Devolucao = withClusters((props: WithClustersProps) => {
  document.title = 'Avaliação | Devolução';

  const [codLoja, setCodLoja] = useState('');
  const [valueTab, setValueTab] = React.useState('aguardando_pagamento');
  const [devolucoes, setDevolucoes] = useState<ListaDevolucoes | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [codProduto, setCodProduto] = useState<string>('');
  const [modalEditarEndereco, setModalEditarEndereco] = useState<boolean>(false);

  const navigate = useNavigate();

  const { getStatusData, statusPage, statusBatchData, statusPedidoData, isLoading, statusMeta, refreshPedido } =
    useFetchPedidoStatus({
      codloja: devolucoes?.vendedora.codloja,
      status_inicial: 'aguardando_pagamento'
    });

  const { fetchClusterDevolucao, clustersDevolucao } = props;

  /**
   * Busca por nome
   */
  const handleCodLoja = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodLoja(e.target.value);
  };

  /**
   * Controla disparo do enter para localizar produto por bip
   */

  const handleLocalizarPorCodigoBip = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (codProduto) {
        setLoading(true);
        fetchByCodProduto(codProduto);
      }
    }
  };

  const fetchByCodProduto = (codproduto: string) => {
    ItensEmDevolucaoService.list(`codproduto=${codproduto}`)
      .then(response => {
        setDevolucoes(response.data.data as ListaDevolucoes);
      })
      .catch(() => {
        setCodProduto('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // eslint-disable-line
    setValueTab(newValue);
    getStatusData({ status: newValue, page: 1 });
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, page: number) => {
    getStatusData({ page });
  };

  const handleCodProduto = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodProduto(event.target.value);
  };

  const handleCloseModalEditarEndereco = () => {
    setModalEditarEndereco(false);
    updateListagem();
  };

  const updateListagem = () => {
    if (codLoja) {
      fetchDevolucoes(codLoja);
    } else if (codProduto) {
      fetchByCodProduto(codProduto);
    }

    refreshPedido();
  };

  /**
   * fetchDevolucoes
   * @param codloja
   * @description request de devoluções de acordo com o código alpha numérico da loja
   */

  const fetchDevolucoes = (codloja: string) => {
    setLoading(true);
    ItensEmDevolucaoService.list(`codloja=${codloja}`)
      .then(response => {
        setDevolucoes(response.data.data as ListaDevolucoes);

        if (response.data.data.vendedora.cliente.codcliente) {
          fetchClusterDevolucao(response.data.data.vendedora.cliente.codcliente);
        }
      })
      .catch(e => {
        if (e.response.status === 500) {
          Swal.fire('Oops', e.response.data.message, 'error');
        }

        setCodLoja('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * handleBuscarCodLoja
   * @description Faz busca de devoluções de acordo com o codloja alpha numérico
   */

  const handleBuscarCodLoja = () => fetchDevolucoes(codLoja);

  /**
   * handleEnterBuscarCodLoja
   * @description Controla Enter do botão buscar, para fazer busca de devolucao por codloja alphanumérico
   */

  const handleEnterBuscarCodLoja = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleBuscarCodLoja();
  };

  const renderNenhumResultado = () => (
    <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
      <WarningIcon fontSize="large" color="disabled" />
      <Box component="span" ml={2}>
        Nenhum resultado encontrado
      </Box>
    </Box>
  );

  /**
   * handleGerarDevolucao
   * @description Controla disparo de botão que chama o endpoit que gera pedido de devolução
   */

  // const handleGerarDevolucao = () => {
  //   setLoadingFull(true);
  //   ItensEmDevolucaoService.criar_devolucao(devolucoes?.vendedora.codigo as string)
  //     .then(() => {
  //       Swal.fire('Pedido gerado com sucesso', '', 'success').then(({ isConfirmed }) => {
  //         if (isConfirmed) {
  //           fetchDevolucoes(devolucoes?.vendedora.codigo as string);
  //         }
  //       });
  //     })
  //     .finally(() => {
  //       setLoadingFull(false);
  //     });
  // };

  /**
   * handleLimpar
   * @description Controla botão "limpar" form de busca
   */

  const handleLimpar = () => {
    setCodLoja('');
    setDevolucoes(null);
    setCodProduto('');
  };

  return (
    <TemplateBase>
      <Box mb={5}>
        <Paper elevation={1}>
          <Card>
            <CardContent>
              <h3>Identificar produto</h3>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    autoFocus
                    value={codProduto}
                    onKeyUp={handleLocalizarPorCodigoBip}
                    onChange={handleCodProduto}
                    InputProps={{
                      id: 'codigoProduto',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Barcode />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Buscar por Cod. Loja"
                    placeholder="Buscar Loja"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={codLoja}
                    onChange={handleCodLoja}
                    onKeyUp={handleEnterBuscarCodLoja}
                  />
                </Grid>
                <Grid item>
                  <Button color="primary" variant="contained" onClick={handleBuscarCodLoja}>
                    Buscar
                  </Button>
                </Grid>

                <Grid item>
                  <Button color="primary" variant="contained" onClick={handleLimpar}>
                    Limpar
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
        {loading && <Box my={2}>carregando...</Box>}
        {!loading && devolucoes && (
          <Box mt={3}>
            <Box display="inline-flex" width="100%" justifyContent="space-between" alignItems="center">
              <strong>
                {devolucoes?.vendedora.nome} | {devolucoes?.vendedora.codloja}
              </strong>
              <Button color="primary" variant="contained" onClick={() => setModalEditarEndereco(true)}>
                Editar endereço
              </Button>
            </Box>
            <Box my={3}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <strong>Cód: </strong>
                    <span>{devolucoes?.vendedora.codigo}</span>
                  </Grid>
                  <Grid item xs={3}>
                    <strong>CEP: </strong>
                    <span>{formatTextCep(devolucoes?.vendedora.cep)}</span>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <strong>CPF: </strong>
                    <span>{formatTextCpf(devolucoes.vendedora.cpf)}</span>
                  </Grid>
                  <Grid item xs={3}>
                    <strong>Rua: </strong>
                    <span>{devolucoes?.vendedora.endereco}</span>
                  </Grid>
                  <Grid item xs={3}>
                    <strong>Número: </strong>
                    <span>{devolucoes?.vendedora.numero}</span>
                  </Grid>
                  <Grid item xs={3}>
                    <strong>Complemento: </strong>
                    <span>{devolucoes?.vendedora.complemento}</span>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <strong>Telefone: </strong>
                    <span>{formatTextPhone(devolucoes?.vendedora.telefone1)}</span>
                  </Grid>
                  <Grid item xs={3}>
                    <strong>Cidade: </strong>
                    <span>{devolucoes?.vendedora.cidade}</span>
                  </Grid>
                  <Grid item xs={3}>
                    <strong>Estado: </strong>
                    <span>{devolucoes?.vendedora.uf}</span>
                  </Grid>
                </Grid>
              </div>
            </Box>
            <Grid container>
              <Grid item xs={6}>
                {clustersDevolucao.length > 0 &&
                  clustersDevolucao.map(cluster => <BadgeClusters key={cluster.id} cluster={cluster} />)}
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" alignItems="flex-end" justifyContent="flex-end" height="100%">
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      navigate(`/avaliacao/incluir-devolucao/${devolucoes?.vendedora.codigo}`);
                    }}
                  >
                    Incluir nova devolução
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Box my={2} display="flex" justifyContent="space-between">
              <Tabs
                value={valueTab}
                onChange={handleChange}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab value="batchs_aguardando_devolucao" label="Aguardando finalizar batch" />
                <Tab value="aguardando_pagamento" label="Aguardando pagamento" />
                <Tab value="em_separacao" label="Em separação" />
                <Tab value="em_processo_envio" label="Em Processo de envio" />
                <Tab value="finalizado" label="Concluída/Entregue" />
                <Tab value="finalizado_doacao" label="Não pago" />
              </Tabs>
            </Box>
            {isLoading ? (
              <CardSkeleton />
            ) : (
              <>
                {valueTab === 'batchs_aguardando_devolucao' && (
                  <div>
                    {statusBatchData.length > 0
                      ? statusBatchData?.map((batch: QueroVenderBatch) => {
                          return (
                            <CardDevolucao
                              key={batch.codquerovender}
                              color={grey[500]}
                              vendedora={devolucoes?.vendedora}
                              contentHeaderLeft="Aguardando para finalizar o batch"
                              contentHeaderRight={<span />}
                              hideAcoesPedido
                              refreshSearch={updateListagem}
                            >
                              {batch.itens.map(item => (
                                <CardDevolucaoItem
                                  key={item.codquerovenderitem}
                                  nome={item?.produto?.nome}
                                  item={item}
                                  thumbnail={item.produto?.imagens[0]?.thumbnail}
                                />
                              ))}
                            </CardDevolucao>
                          );
                        })
                      : renderNenhumResultado()}
                  </div>
                )}

                {valueTab === 'aguardando_pagamento' && (
                  <div>
                    {statusPedidoData.length >= 1
                      ? statusPedidoData?.map((pedido: Pedido) => {
                          return (
                            <CardDevolucao
                              key={pedido?.codpedido}
                              vendedora={devolucoes?.vendedora}
                              retirada={pedido?.retirada}
                              color={orange[500]}
                              contentHeaderLeft="Aguardando pagamento"
                              contentHeaderRight={<span />}
                              refreshSearch={updateListagem}
                              codigo={pedido.codigo}
                              codpedido={pedido.codpedido}
                              returnType={pedido.tipo_devolucao?.label}
                            >
                              {pedido.produtos.map(produto => {
                                return (
                                  <CardDevolucaoItem
                                    key={produto?.codproduto}
                                    nome={produto?.nome}
                                    item={produto.item}
                                    thumbnail={produto?.imagens[0]?.thumbnail}
                                    returnDate={pedido?.data}
                                  />
                                );
                              })}
                            </CardDevolucao>
                          );
                        })
                      : renderNenhumResultado()}
                  </div>
                )}

                {valueTab === 'em_separacao' && (
                  <div>
                    {statusPedidoData.length >= 1
                      ? statusPedidoData?.map((pedido: Pedido) => {
                          return (
                            <CardDevolucao
                              key={pedido?.codpedido}
                              vendedora={devolucoes?.vendedora}
                              retirada={pedido?.retirada}
                              color={red[500]}
                              contentHeaderLeft="Em separação"
                              contentHeaderRight={<span />}
                              hideAcoesPedido
                              refreshSearch={updateListagem}
                              codigo={pedido.codigo}
                              codpedido={pedido.codpedido}
                              returnType={pedido.tipo_devolucao?.label}
                            >
                              {pedido.produtos.map(produto => {
                                return (
                                  <CardDevolucaoItem
                                    key={produto?.codproduto}
                                    nome={produto?.nome}
                                    item={produto.item}
                                    thumbnail={produto?.imagens[0]?.thumbnail}
                                  />
                                );
                              })}
                            </CardDevolucao>
                          );
                        })
                      : renderNenhumResultado()}
                  </div>
                )}

                {valueTab === 'em_processo_envio' && (
                  <div>
                    {statusPedidoData.length >= 1
                      ? statusPedidoData?.map((pedido: Pedido) => {
                          return (
                            <CardDevolucao
                              key={pedido?.codpedido}
                              vendedora={devolucoes?.vendedora}
                              color={blue[500]}
                              retirada={pedido?.retirada}
                              contentHeaderLeft="Em processo de envio"
                              hideAcoesPedido
                              contentHeaderRight={<span />}
                              refreshSearch={updateListagem}
                              codigo={pedido.codigo}
                              codpedido={pedido.codpedido}
                              returnType={pedido.tipo_devolucao?.label}
                            >
                              {pedido.produtos.map(produto => {
                                return (
                                  <CardDevolucaoItem
                                    key={produto?.codproduto}
                                    nome={produto?.nome}
                                    item={produto.item}
                                    thumbnail={produto?.imagens[0]?.thumbnail}
                                  />
                                );
                              })}
                            </CardDevolucao>
                          );
                        })
                      : renderNenhumResultado()}
                  </div>
                )}

                {valueTab === 'finalizado' && (
                  <div>
                    {statusPedidoData.length >= 1
                      ? statusPedidoData?.map((pedido: Pedido) => {
                          return (
                            <CardDevolucao
                              key={pedido?.codpedido}
                              retirada={pedido?.retirada}
                              vendedora={devolucoes?.vendedora}
                              color={green[500]}
                              contentHeaderLeft="devolução finalizada"
                              contentHeaderRight={<span />}
                              refreshSearch={updateListagem}
                              hideAcoesPedido
                              codigo={pedido.codigo}
                              codpedido={pedido.codpedido}
                              returnType={pedido.tipo_devolucao?.label}
                            >
                              {pedido.produtos.map(produto => {
                                return (
                                  <CardDevolucaoItem
                                    key={produto?.codproduto}
                                    nome={produto?.nome}
                                    item={produto.item}
                                    thumbnail={produto?.imagens[0]?.thumbnail}
                                  />
                                );
                              })}
                            </CardDevolucao>
                          );
                        })
                      : renderNenhumResultado()}
                  </div>
                )}

                {valueTab === 'finalizado_doacao' && (
                  <div>
                    {statusPedidoData.length >= 1
                      ? statusPedidoData?.map((pedido: Pedido) => {
                          return (
                            <CardDevolucao
                              key={pedido?.codpedido}
                              vendedora={devolucoes?.vendedora}
                              color={green[500]}
                              hideAcoesPedido
                              pedidoNaoPago
                              contentHeaderLeft={
                                <>
                                  <div>devolução finalizada</div>{' '}
                                  <div style={{ color: red[500] }}>&nbsp;(pagamento não identificado)</div>
                                </>
                              }
                              contentHeaderRight={<span />}
                              refreshSearch={updateListagem}
                              codigo={pedido.codigo}
                              codpedido={pedido.codpedido}
                              returnType={pedido.tipo_devolucao?.label}
                              refreshPedido={refreshPedido}
                            >
                              {pedido.produtos.map(produto => {
                                return (
                                  <CardDevolucaoItem
                                    key={produto?.codproduto}
                                    nome={produto?.nome}
                                    item={produto.item}
                                    thumbnail={produto?.imagens[0]?.thumbnail}
                                  />
                                );
                              })}
                            </CardDevolucao>
                          );
                        })
                      : renderNenhumResultado()}
                  </div>
                )}
              </>
            )}

            {statusMeta?.last_page && statusMeta?.last_page > 1 && (
              <Box display="flex" justifyContent="center" mt={3}>
                <Pagination
                  color="primary"
                  count={statusMeta?.last_page}
                  page={statusPage}
                  onChange={handlePagination}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>

      {modalEditarEndereco && (
        <ModalEditarEndereco onClose={handleCloseModalEditarEndereco} vendedora={devolucoes?.vendedora} />
      )}
    </TemplateBase>
  );
});

export default Devolucao;
