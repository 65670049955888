import { yupResolver } from '@hookform/resolvers/yup';
import { CloudUpload } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { IGetDetailsCampaign, ITypeUpload } from 'services/painel-marketing/types';
import { CampanhasService } from 'services/painel-marketing/CampanhasService';
import { useLoading } from 'context/loading';
import Select from '@mui/material/Select';
import { VisuallyHiddenInput } from '../components/visually-hidden-input';
import { orderDefault, templateHeaderOptions } from '../constants';
import { RouteParams } from '.';

export const schema = yup
  .object({
    nome: yup.string().required('Título é obrigatório'),
    url: yup.string().required('Slug é obrigatório'),
    descricao: yup.string().optional(),
    templateheader: yup.string().required('Template do header é obrigatório'),
    mostrarsidebar: yup.boolean().required(),
    ordenacao: yup.string().required(),
    exibirtrends: yup.boolean().required(),
    exibirdestaquetrends: yup.boolean().required(),
    exibirhome: yup.boolean().required(),
    ordemexibicao: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
    tagtitle: yup.string().optional(),
    metadescricao: yup.string().optional(),
    nofollow: yup.boolean().required(),
    noindex: yup.boolean().required(),
    imagemcapa: yup.string().required('Imagem de capa é obrigatório'),
    imagem: yup.string().optional()
  })
  .required({});

type FormData = yup.InferType<typeof schema>;

type Props = {
  campaign?: IGetDetailsCampaign;
  onUpdate: (campaign: IGetDetailsCampaign) => void;
};

export function Configuracoes({ campaign, onUpdate }: Props) {
  const { setLoading } = useLoading();
  const { id } = useParams<RouteParams>();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    values: {
      descricao: campaign?.descricao ?? '',
      exibirdestaquetrends: !!campaign?.exibirdestaquetrends,
      exibirhome: !!campaign?.exibirhome,
      exibirtrends: !!campaign?.exibirtrends,
      imagemcapa: campaign?.imagemcapa ?? '',
      nome: campaign?.nome ?? '',
      nofollow: !!campaign?.seorobots?.nofollow,
      noindex: !!campaign?.seorobots?.noindex,
      ordenacao: campaign?.ordenacao ?? 'default',
      mostrarsidebar: !!campaign?.mostrarsidebar,
      ordemexibicao: campaign?.ordemexibicao ?? null,
      tagtitle: campaign?.tagtitle ?? '',
      templateheader: campaign?.templateheader ?? '',
      url: campaign?.url ?? '',
      metadescricao: campaign?.metadescricao ?? ''
    }
  });

  const [imageHeader, setImageHeader] = useState<File>();
  const [imageThumbs, setImageThumbs] = useState<File>();

  useEffect(() => {
    if (campaign) {
      setValue('ordenacao', campaign?.ordenacao ? campaign.ordenacao : 'default');
    }
  }, [campaign, setValue]);

  const handleEdit = async (data: FormData) => {
    setLoading(true);
    const newCampaign = await CampanhasService.editarCampanha(id!, {
      nome: data.nome,
      url: data.url,
      imagem: data.imagem,
      imagemcapa: data.imagemcapa,
      descricao: data.descricao,
      templateheader: data.templateheader,
      mostrarsidebar: data.mostrarsidebar,
      ordenacao: data.ordenacao === 'default' ? '' : data.ordenacao,
      exibirtrends: data.exibirtrends,
      exibirdestaquetrends: data.exibirdestaquetrends,
      exibirhome: data.exibirhome,
      tagtitle: data.tagtitle,
      metadescricao: data.metadescricao,
      seorobots: {
        noindex: data.noindex,
        nofollow: data.nofollow
      },
      ordemexibicao: data.ordemexibicao
    });
    onUpdate(newCampaign);
    setLoading(false);
    Swal.fire('Campanha editada!', '', 'success');
  };

  const templateHeaderWatch = watch('templateheader');

  const urlImageHeader = useMemo(
    // eslint-disable-next-line no-nested-ternary
    () => (imageHeader ? URL.createObjectURL(imageHeader) : campaign?.imagem ? campaign.imagem : ''),
    [imageHeader, campaign?.imagem]
  );

  const urlImageThumbs = useMemo(
    // eslint-disable-next-line no-nested-ternary
    () => (imageThumbs ? URL.createObjectURL(imageThumbs) : campaign?.imagemcapa ? campaign.imagemcapa : ''),
    [imageThumbs, campaign?.imagemcapa]
  );

  const uploadImage = async (type: ITypeUpload, image: File) => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('uso', type);
    const { filename } = await CampanhasService.uploadImage(formData);

    if (type === 'imagem') {
      setValue('imagem', filename, { shouldValidate: true });
    } else {
      setValue('imagemcapa', filename, { shouldValidate: true });
    }
  };

  const handleUploadImage = async (event: React.ChangeEvent<HTMLInputElement>, type: ITypeUpload) => {
    const image = event.target.files?.[0];

    // eslint-disable-next-line no-useless-return
    if (!image) return;

    if (type === 'imagem') {
      setImageHeader(image);
    } else {
      setImageThumbs(image);
    }

    try {
      await uploadImage(type, image);
    } catch {
      if (type === 'imagem') {
        setImageHeader(undefined);
      } else {
        setImageThumbs(undefined);
      }
    }
  };

  if (!campaign) {
    return null;
  }

  return (
    <Box mt={2} gap={2} display="flex" flexDirection="column">
      <TextField
        {...register('nome')}
        error={!!errors.nome}
        helperText={errors.nome?.message}
        label="Título"
        placeholder="Título"
        variant="outlined"
        fullWidth
      />

      <TextField
        {...register('url')}
        error={!!errors.url}
        helperText={errors.url?.message}
        variant="outlined"
        placeholder="Slug"
        label="Slug"
        fullWidth
      />
      <TextField
        {...register('descricao')}
        variant="outlined"
        placeholder="Texto do header"
        label="Texto do header"
        fullWidth
        multiline
        rows={4}
      />
      <Box display="flex" flexDirection="column">
        <p>Imagem do header</p>
        {urlImageHeader && <img width={202} height={176} src={urlImageHeader} alt="Imagem do header" />}
        <Button style={{ width: 300 }} component="label" variant="contained" tabIndex={-1} startIcon={<CloudUpload />}>
          Selecione um arquivo
          <VisuallyHiddenInput type="file" onChange={event => handleUploadImage(event, 'imagem')} />
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" mb={1}>
        <p>Capa thumbs (1024 X 768)</p>
        {urlImageThumbs && <img width={202} height={176} src={urlImageThumbs} alt="Imagem de capa" />}
        <Button style={{ width: 300 }} component="label" variant="contained" tabIndex={-1} startIcon={<CloudUpload />}>
          Selecione um arquivo
          <VisuallyHiddenInput type="file" onChange={event => handleUploadImage(event, 'imagemcapa')} />
        </Button>
      </Box>

      <TextField
        {...register('templateheader')}
        error={!!errors.templateheader}
        helperText={errors.templateheader?.message}
        select
        label="Template do header"
        value={templateHeaderWatch}
      >
        {templateHeaderOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Box>
        <p>Exibição sidebar de filtros?</p>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox defaultChecked={campaign.mostrarsidebar} {...register('mostrarsidebar')} />}
            label="Sim"
          />
        </FormGroup>
      </Box>

      {campaign && (
        <FormControl fullWidth>
          <InputLabel id="label-order">Ordenação padrão</InputLabel>
          <Select
            {...register('ordenacao')}
            labelId="label-order"
            id="demo-simple-select"
            label="Ordenação padrão"
            defaultValue={campaign.ordenacao ? campaign.ordenacao : 'default'}
          >
            {orderDefault.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Box>
        <p>Exibição</p>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox {...register('exibirtrends')} defaultChecked={campaign.exibirtrends} />}
            label="Página de trends"
          />
          <FormControlLabel
            control={<Checkbox {...register('exibirdestaquetrends')} defaultChecked={campaign.exibirdestaquetrends} />}
            label="Destaque na página de trends"
          />
          <FormControlLabel
            control={<Checkbox {...register('exibirhome')} defaultChecked={campaign.exibirhome} />}
            label="Carrousel da home"
          />
        </FormGroup>
      </Box>
      <TextField
        slotProps={{ htmlInput: { min: 1 } }}
        type="number"
        {...register('ordemexibicao')}
        label="Ordem de exibição carrousel"
      />

      <TextField
        {...register('tagtitle')}
        variant="outlined"
        placeholder="SEO: Page Title"
        label="SEO: Page Title"
        fullWidth
      />

      <TextField
        {...register('metadescricao')}
        variant="outlined"
        placeholder="SEO: Meta Descriptions"
        label="SEO: Meta Descriptions"
        fullWidth
        multiline
        rows={4}
      />
      <Box>
        <p>SEO: Meta Robots</p>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox {...register('nofollow')} defaultChecked={!!campaign?.seorobots?.nofollow} />}
            label="nofollow"
          />
          <FormControlLabel
            control={<Checkbox {...register('noindex')} defaultChecked={!!campaign?.seorobots?.noindex} />}
            label="noindex"
          />
        </FormGroup>
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        <Button
          style={{ height: 36.5 }}
          variant="outlined"
          onClick={() => {
            navigate('/painel-marketing/campanhas');
          }}
        >
          Voltar
        </Button>
        <Button style={{ height: 36.5 }} variant="contained" color="success" onClick={handleSubmit(handleEdit)}>
          Salvar
        </Button>
      </Box>
    </Box>
  );
}
