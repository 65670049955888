import api from 'services/api';
import {
  ICreateCampaignParams,
  IEditCampaignParams,
  IGetDetailsCampaign,
  IResponseAddProduct,
  IResponseCreateCampaign,
  IResponseGetCampanhas,
  IResponseGetProductsCampaign,
  IResponseSearchProducts,
  IUploadImageResponse,
  StatusCampaign
} from './types';

export const CampanhasService = {
  getCampanhas: async (page: number, query?: string) => {
    const response = await api.get<IResponseGetCampanhas>('campanhas', {
      params: { page, per_page: 10, query: query ?? null }
    });
    return response.data;
  },
  uploadImage: async (formData: FormData) => {
    const response = await api.post<IUploadImageResponse>('campanhas/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  },
  criarCampanha: async (data: ICreateCampaignParams) => {
    const response = await api.post<IResponseCreateCampaign>('campanhas', data);
    return response.data;
  },
  editarCampanha: async (id: string, data: IEditCampaignParams) => {
    const response = await api.patch<IGetDetailsCampaign>(`campanhas/${id}`, data);
    return response.data;
  },
  toggleStatusCampanha: async (id: string, status: StatusCampaign) => {
    const response = await api.patch(`campanhas/${id}`, { status });
    return response.data;
  },
  deletarCampanha: async (id: number) => {
    await api.delete(`campanhas/${id}`);
  },
  getDetalhesCampanha: async (id: string) => {
    const response = await api.get<IGetDetailsCampaign>(`campanhas/${id}`);
    return response.data;
  },
  getProdutosDaCampanha: async (id: string, page: number) => {
    const response = await api.get<IResponseGetProductsCampaign>(`campanhas/${id}/produtos?page=${page}&per_page=10`);
    return response.data;
  },
  removeProduct: async (campaignId: string, productId: number) => {
    await api.delete(`/campanhas/${campaignId}/produtos`, { data: { codproduto: productId } });
  },
  buscarProdutos: async (query: string) => {
    const response = await api.get<IResponseSearchProducts>('produtos/buscar', {
      params: { q: query }
    });
    return response.data;
  },
  adicionarProduto: async (campaignId: string, productId: number) => {
    const response = await api.post<IResponseAddProduct>(`campanhas/${campaignId}/produtos`, { codproduto: productId });
    return response.data;
  }
};
