import React from 'react';
import Box from '@mui/material/Box';

import customBadgeStyles from './styles';

export type CustomBadgeProps = {
  bgcolor: string;
  color: string;
  icon: React.ReactNode;
  text: string;
  bold?: boolean;
  nospace?: boolean;
  style?: React.CSSProperties;
};

export function CustomBadge(props: CustomBadgeProps) {
  const { bgcolor, color, icon, text, bold = false, nospace = false, style } = props;

  const styles = customBadgeStyles();
  const inlineStyles: React.CSSProperties = style ?? {};

  if (bold) {
    inlineStyles.fontWeight = 700;
  }

  if (nospace) {
    inlineStyles.marginRight = 0;
    inlineStyles.marginTop = 0;
  }

  return (
    <Box component="div" className={styles.wrapper} style={inlineStyles} bgcolor={bgcolor} color={color}>
      <Box component="span" display="inline-flex" alignItems="center" minHeight={20}>
        {icon}
      </Box>
      <span>&nbsp;</span>
      <Box component="span" flex={1}>
        {text}
      </Box>
    </Box>
  );
}
